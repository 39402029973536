import React from "react"

import Layout from "../components/layout-static"
import Seo from "../components/seo"


const Page = () => {
  const metadata = {
    title: "NORWICH CT MOTORCYCLE RIDER DIES AFTER CRASH",
    heroH1: "NORWICH CT MOTORCYCLE RIDER DIES AFTER CRASH",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <p className="mb-8">
              Andre J. Ross of Norwich was fatally injured in an accident at 2
              A.M. Saturday morning. No other vehicles were involved. If anyone
              has information about the accident please call Norwich Officer
              Andre Rosedal at the Norwich Police Department 860-886-5561.
            </p>
            <p className="mb-8">
              If you or a loved one has been injured or died in an accident in
              Connecticut and need legal advice call our firm now at
              <span class="bg-phone-number">203-463-4939</span>.
            </p>
          </div>
        </div>

      </div>
    </Layout>
  )
}

export default Page
